import { storeDataObject, getDataObject } from '../../utils/AsyncStorage'
import { taskProjectType } from '../../types'

const setTaskMemory = async (costCenters: Array<taskProjectType>) => {
  await storeDataObject('tasks', costCenters)
}
const getTaskMemory = async (): Promise<taskProjectType[]> => {
  const data = await getDataObject('tasks')
  return data
}
const getTaskMemoryById = async (
  taskId: number
): Promise<taskProjectType[]> => {
  const data = await getDataObject('tasks')
  return data?.find((element: taskProjectType) => element.id === taskId)
}

const getTaskByProjectMemory = async (
  projectId: number
): Promise<taskProjectType[]> => {
  const data = await getDataObject('tasks')
  return data?.filter(
    (element: taskProjectType) => element.project_id[0] === projectId
  )
}
export {
  setTaskMemory,
  getTaskMemory,
  getTaskByProjectMemory,
  getTaskMemoryById
}
