import React from 'react'
import { Box, Pressable, Text, useTheme, HStack } from 'native-base'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { MaterialIcons } from '@expo/vector-icons'
import { StyleSheet } from 'react-native'

export type RootStackProps = {
  Home: undefined;
  Login: undefined;
  DailyReport: undefined;
  LoadedReports: undefined;
  HoursReport: undefined;
  Config: undefined;
};

type Props = NativeStackScreenProps<RootStackProps, 'Home'>;
const styles = StyleSheet.create({
  presseable: {
    borderRadius: 10,
    padding: 10,
    minHeight: 150,
    textAlign: 'center',
    width: '45%',
    margin: 5
  },
  box: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    flexDirection: 'row',
    padding: 10,
    justifyContent: 'space-around',
    height: '100%'
  }
})
const Home = ({ navigation }: Props) => {
  const { colors } = useTheme()
  return (
    <Box style={styles.box} backgroundColor={colors.primary[50]}>
      <Pressable
        shadow={'9'}
        backgroundColor={colors.primary[700]}
        onPress={() => navigation.navigate('DailyReport')}
        alignContent="center"
        style={styles.presseable}
      >
        <HStack space={3} justifyContent="center">
          <Text color={'white'} textAlign={'center'} fontSize={'lg'}>
            Nuevo parte de diario
          </Text>
        </HStack>
        <HStack space={3} paddingTop={2} justifyContent="center">
          <MaterialIcons name="save" size={62} color="white" />
        </HStack>
      </Pressable>
      <Pressable
        shadow={'9'}
        onPress={() => navigation.navigate('LoadedReports')}
        backgroundColor={colors.primary[700]}
        style={styles.presseable}
      >
        <HStack space={3} justifyContent="center">
          <Text textAlign={'center'} color={'white'} fontSize={'lg'}>
            Mis parte de horas
          </Text>
        </HStack>
        <HStack space={3} paddingTop={2} justifyContent="center">
          <MaterialIcons name="done" size={62} color="white" />
        </HStack>
      </Pressable>

      <Pressable
        shadow={'9'}
        backgroundColor={colors.primary[700]}
        onPress={() => navigation.navigate('ApproverHours')}
        style={styles.presseable}
      >
        <HStack space={3} justifyContent="center">
          <Text textAlign={'center'} color={'white'} fontSize={'lg'}>
            Aprobación
          </Text>
        </HStack>
        <HStack space={3} paddingTop={2} justifyContent="center">
          <MaterialIcons name="insights" size={62} color="white" />
        </HStack>
      </Pressable>
      <Pressable
        shadow={'9'}
        backgroundColor={colors.primary[700]}
        onPress={() => navigation.navigate('Config')}
        style={styles.presseable}
      >
        <HStack space={3} justifyContent="center">
          <Text textAlign={'center'} color={'white'} fontSize={'lg'}>
            Configuración
          </Text>
        </HStack>
        <HStack space={3} paddingTop={2} justifyContent="center">
          <MaterialIcons name="settings" size={62} color="white" />
        </HStack>
      </Pressable>
      <Pressable
        shadow={'9'}
        backgroundColor={colors.primary[700]}
        onPress={() => navigation.navigate('Resume')}
        style={styles.presseable}
      >
        <HStack space={3} justifyContent="center">
          <Text textAlign={'center'} color={'white'} fontSize={'lg'}>
            Resumen
          </Text>
        </HStack>
        <HStack space={3} paddingTop={2} justifyContent="center">
          <MaterialIcons name="leaderboard" size={62} color="white" />
        </HStack>
      </Pressable>
    </Box>
  )
}

export default Home
