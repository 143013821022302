import {
  Button,
  CheckIcon,
  FormControl,
  Input,
  ScrollView,
  Select,
  Text,
  VStack,
  HStack,
  Alert,
  CloseIcon,
  IconButton,
  Box,
  Pressable,
  Modal,
  useTheme
} from 'native-base'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { usePreload } from '../contexts/PreloadContext'
import { costCenterType } from '../types'
import DatePickerComponent from '../components/DatePickerComponent'
import { useRoute } from '@react-navigation/native'
import moment from 'moment'
import { Table, Row } from 'react-native-table-component'
import { getEmployeesMemoryById } from '../services/OdooServiceMemory'
type FormProps = {
  date: string;
  receipt_number: string;
  name?: string;
  project_id?: number;
  partner_id?: number;
};

const DailyReportComponent = ({ navigation }: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const { colors } = useTheme()
  const [showModal, setShowModal] = useState(false)
  const [element, setElement] = useState({})
  const route = useRoute()
  const { getCostCenter } = usePreload()
  const [costCenters, setCostCenters] = useState<costCenterType[]>([])
  const [clientSelected, setClientSelected] = useState<Array<string | number>>(
    []
  )
  const state = {
    tableHead: ['Persona', 'Horas'],
    widthArr: [180, 70]
  }
  const [partHour, setPartHour] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [formState, setFormState] = useState<FormProps>({
    date: moment().format('YYYY-MM-DD HH:mm'),
    name: ' ',
    receipt_number: ''
  })
  useEffect(() => {
    if (route.params) {
      if (route.params.delete) {
        setPartHour(partHour.filter((e) => e.id !== route.params.delete))
      } else {
        getEmployeesMemoryById(route.params.employee_id).then(
          (employeeName) => {
            route.params.employeeName = employeeName
            setPartHour([...partHour, route.params])
          }
        )
      }
    }
  }, [route.params])

  useEffect(() => {
    getCostCenter().then(setCostCenters)
  }, [])

  const onSubmit = async (): Promise<void> => {
    if (partHour && partHour.length) {
      navigation.navigate('HoursReportByReceiptNumber', {
        partHour,
        date: formState.date,
        formState
      })
    } else {
      setShowAlert(true)
    }
  }

  const handleCostCenterChange = (value: any) => {
    setClientSelected(value.partner_id)
  }

  const setNewDate = (date) => {
    setFormState({ ...formState, date })
  }
  return (
    <ScrollView maxW={'100%'}>
      <Box
        borderRadius={'5'}
        shadow={'9'}
        backgroundColor={'white'}
        margin={2}
        padding={3}
      >
        {showAlert && (
          <Alert w="100%" status={'error'}>
            <VStack space={2} flexShrink={1} w="100%">
              <HStack flexShrink={1} space={2} justifyContent="space-between">
                <HStack space={2} flexShrink={1}>
                  <Alert.Icon mt="1" />
                  <Text fontSize="md" color="coolGray.800">
                    {'No puede crear un parte diario sin operarios cargados'}
                  </Text>
                </HStack>
                <IconButton
                  variant="unstyled"
                  onPress={() => setShowAlert(false)}
                  _focus={{
                    borderWidth: 0
                  }}
                  icon={<CloseIcon size="3" color="coolGray.600" />}
                />
              </HStack>
            </VStack>
          </Alert>
        )}

        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <Modal.Content>
            <Modal.Body backgroundColor={'white'}>
              <VStack space={3}>
                <Text fontWeight={'black'}>Detalle de parte de horas</Text>
                <Text>
                  Operario:{' '}
                  {element.employeeName ? element.employeeName.name : ''}
                </Text>
                <HStack>
                  <Text>
                    Desde: {element.start_time} Hasta: {element.end_time}
                  </Text>
                </HStack>
                <Text>Horas totales: {element.declared_hours}</Text>
              </VStack>
              <VStack>
                <HStack marginTop={2} space={3}>
                  <Button onPress={() => setShowModal(false)}>Cerrar</Button>
                  <Button
                    onPress={() => {
                      setPartHour(partHour.filter((e) => e.id !== element.id))
                      setShowModal(false)
                    }}
                    style={{ backgroundColor: 'red' }}
                  >
                    Eliminar
                  </Button>
                </HStack>
              </VStack>
            </Modal.Body>
          </Modal.Content>
        </Modal>
        <VStack flex="1" w="95%" space={5}>
          <FormControl isInvalid={'date' in errors}>
            <FormControl.Label>
              <Text fontSize={'md'}>Fecha de emisión</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <DatePickerComponent size={20} sendDate={setNewDate} />
              )}
              name="date"
            />
          </FormControl>
          <FormControl isRequired isInvalid={'receipt_number' in errors}>
            <FormControl.Label>
              <Text>Número de comprobante</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onBlur={onBlur}
                  placeholder={'Número de comprobante'}
                  onChangeText={(val: any) => {
                    setFormState({ ...formState, receipt_number: val })
                    onChange(val)
                  }}
                  value={value}
                  type="text"
                />
              )}
              name="receipt_number"
              rules={{ required: 'El número de comprobante es requerido.' }}
              defaultValue=""
            />
            <FormControl.ErrorMessage>
              {errors.receipt_number?.message}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={'project_id' in errors}>
            <FormControl.Label>
              <Text>Centro de costo</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  w={'100%'}
                  selectedValue={value}
                  accessibilityLabel="Elija el centro de costo"
                  placeholder="Centro de costo"
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size="5" />
                  }}
                  mt={1}
                  onValueChange={(val) => {
                    const center = costCenters.find(
                      (e) => e.id === Number(val)
                    )
                    onChange(val)
                    setFormState({
                      ...formState,
                      project_id: center?.id,
                      partner_id: center?.partner_id[0]
                    })
                    handleCostCenterChange(center)
                  }}
                >
                  {costCenters?.map((el: costCenterType) => (
                    <Select.Item
                      key={el.id}
                      label={el.name}
                      value={String(el.id)}
                    />
                  ))}
                </Select>
              )}
              name="project_id"
              rules={{ required: 'El centro de costo es requerido.' }}
              defaultValue=""
            />
            <FormControl.ErrorMessage>
              {errors.project_id?.message}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl>
            <FormControl.Label>
              <Text>Cliente</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  margin={0}
                  isReadOnly
                  placeholder={'Cliente'}
                  value={clientSelected.length ? String(clientSelected[1]) : ''}
                />
              )}
              name="client"
              defaultValue=""
            />
          </FormControl>
          <FormControl isInvalid={'name' in errors}>
            <FormControl.Label>
              <Text>Observaciones</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  onBlur={onBlur}
                  placeholder={'Observaciones'}
                  onChangeText={(val: any) => {
                    setFormState({ ...formState, name: val })
                    onChange(val)
                  }}
                  value={value}
                  type="text"
                />
              )}
              name="name"
            />
          </FormControl>
          <Button
            isDisabled={!formState.project_id}
            onPress={() => {
              navigation.navigate('HoursReport', {
                project_id: formState.project_id,
                date: formState.date,
                receipt_number: formState.receipt_number,
                partner_id: formState.partner_id
              })
            }}
          >
            Ingresar operario
          </Button>
          <ScrollView horizontal={true}>
            <Table>
              {partHour.map((rowData, index) => {
                const data = [
                  rowData.employeeName ? rowData.employeeName.name : '',
                  rowData.declared_hours
                    ? parseFloat(rowData.declared_hours).toFixed(2)
                    : '00.00'
                ]
                return (
                  <Pressable
                    key={index}
                    onPress={() => {
                      setElement(rowData)
                      setShowModal(true)
                    }}
                  >
                    <Row
                      textStyle={{
                        color: colors.primary[700],
                        fontSize: 12,
                        padding: 5,
                        fontFamily: 'Exo'
                      }}
                      key={index}
                      data={data}
                      style={{
                        backgroundColor:
                          rowData.declared_hours <= 12
                            ? 'white'
                            : colors.red[300]
                      }}
                      borderStyle={{
                        borderWidth: 0.5,
                        borderColor: colors.primary[500]
                      }}
                      widthArr={state.widthArr}
                    ></Row>
                  </Pressable>
                )
              })}
            </Table>
          </ScrollView>
          <Button onPress={handleSubmit(onSubmit)}>Guardar</Button>
        </VStack>
      </Box>
    </ScrollView>
  )
}

export default DailyReportComponent
