import React, { useState, useEffect } from 'react'
import LoadingComponent from './LoadingComponent'
import {
  ScrollView,
  Pressable,
  useTheme,
  Modal,
  Text,
  Button,
  VStack,
  HStack,
  Box
} from 'native-base'
import * as ImagePicker from 'expo-image-picker'
import uuid from 'react-native-uuid'
import { useSendData } from '../contexts/SendDataContext'
import { getDateTimeBeetwenTwoTimes } from '../utils/date'
import { useRoute } from '@react-navigation/native'
import { Table, Row } from 'react-native-table-component'
import { Image } from 'react-native'
import moment from 'moment'
import { getEmployeesMemoryById } from '../services/employees'
import { SimpleLineIcons, MaterialIcons } from '@expo/vector-icons'

const HoursReportReceiptNumberComponent = ({ navigation }: any) => {
  const route = useRoute()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [element, setElement] = useState({})
  const { createDailyReport, createDailyReportImages } = useSendData()
  const [images, setImages] = useState([])

  const [accountAnalitycLine, setAccountAnalitycLine] = useState([])
  const state = {
    tableHead: ['Fecha', 'Persona', 'Total Horas'],
    widthArr: [90, 180, 100]
  }
  const deleteImage = (uri) => {
    setImages(images.filter((e) => e.uri !== uri))
  }
  const pickDocument = async () => {
    const result = await ImagePicker.launchImageLibraryAsync()
    if (!result.cancelled) {
      setImages([
        ...images,
        { uri: result.uri, base64: result.base64, id: uuid.v4() }
      ])
    }
  }
  const pickImage = async () => {
    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      aspect: [4, 3],
      quality: 0.1,
      base64: true
    })
    if (!result.cancelled) {
      setImages([
        ...images,
        { uri: result.uri, base64: result.base64, id: uuid.v4() }
      ])
    }
  }
  const [date, setDate] = useState('')
  const { colors } = useTheme()
  const submit = async () => {
    setLoading(true)
    if (route.params.partHour && route.params.partHour.length) {
      const res = route.params.partHour.map((e: any) => {
        const times = getDateTimeBeetwenTwoTimes(
          e.start_time,
          e.end_time,
          route.params.formState.date
        )
        const obj = {
          task_id: e.task_id,
          employee_id: e.employee_id,
          receipt_number: route.params.formState.receipt_number,
          name: route.params.formState.name,
          date: route.params.formState.date,
          start_datetime: times.start_datetime,
          end_datetime: times.end_datetime,
          project_id: route.params.formState.project_id,
          partner_id: route.params.formState.partner_id,
          declared_hours: e.declared_hours,
          status: 'draft',
          id: e.id
        }

        return obj
      })

      if (res && res.length) {
        await createDailyReport(res)
        if (images && images.length) {
          const receiptNumber = res[0].receipt_number
          const projectId = res[0].project_id
          const resImages = images.map((e) => {
            return {
              receipt_number: receiptNumber,
              project_id: projectId,
              receipt_image: e.base64,
              id: e.id
            }
          })
          await createDailyReportImages(resImages)
        }
        setLoading(false)

        navigation.navigate('Home')
      }
    }
    setLoading(false)
  }
  useEffect(() => {
    const mappedDate = async () => {
      const dataReturn = []
      for (let i = 0; i < route.params.partHour.length; i++) {
        const employeeId = await getEmployeesMemoryById(
          route.params.partHour[i].employee_id
        )

        dataReturn.push({
          start_time: route.params.partHour[i].start_time,
          end_time: route.params.partHour[i].end_time,
          declared_hours: route.params.partHour[i].declared_hours,
          task_id: route.params.partHour[i].task_id,
          date: route.params.date,
          employee_id: employeeId,
          status: route.params.status,
          state_untested: route.params.state_untested,
          id: route.params.partHour[i].id
        })
      }
      return dataReturn
    }
    if (route.params.partHour && route.params.partHour.length) {
      mappedDate().then((res) => {
        setAccountAnalitycLine(res)
        setDate(route.params.date)
      })
    }
  }, [])

  return (
    <ScrollView>
      {loading && <LoadingComponent />}
      {!loading && (
        <Box
          borderRadius={'5'}
          shadow={'9'}
          backgroundColor={'white'}
          margin={2}
          padding={3}
        >
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <Modal.Content>
              <Modal.Body backgroundColor={'white'}>
                <VStack space={3}>
                  <Text fontWeight={'black'}>Detalle de parte de horas</Text>
                  <Text>Fecha: {element.date}</Text>
                  <Text>
                    Operario:{' '}
                    {element.employee_id ? element.employee_id.name : ''}
                  </Text>
                  <HStack>
                    <Text>
                      Desde: {element.start_time} Hasta: {element.end_time}
                    </Text>
                  </HStack>
                  <Text>Horas totales: {element.declared_hours}</Text>
                </VStack>
                <VStack>
                  <HStack marginTop={2} space={3}>
                    <Button onPress={() => setShowModal(false)}>Cerrar</Button>
                    <Button
                      onPress={() =>
                        navigation.navigate('DailyReport', {
                          delete: element.id
                        })
                      }
                      style={{ backgroundColor: 'red' }}
                    >
                      Eliminar
                    </Button>
                  </HStack>
                </VStack>
              </Modal.Body>
            </Modal.Content>
          </Modal>
          <ScrollView horizontal={true}>
            <Box alignItems={'center'}>
              <Table>
                <Row
                  textStyle={{
                    color: 'white',
                    fontSize: 14,
                    padding: 5,
                    fontFamily: 'Exo'
                  }}
                  data={state.tableHead}
                  style={{
                    backgroundColor: colors.primary[700]
                  }}
                  widthArr={state.widthArr}
                />
              </Table>
              <ScrollView>
                <Table>
                  {accountAnalitycLine.map((rowData, index) => {
                    const data = [
                      date ? moment(date).format('DD/MM/YY') : '',
                      rowData.employee_id ? rowData.employee_id.name : '',
                      rowData.declared_hours
                        ? parseFloat(rowData.declared_hours).toFixed(2)
                        : '00.00'
                    ]
                    return (
                      <Pressable
                        onPress={() => {
                          setElement(rowData)
                          setShowModal(true)
                        }}
                        key={index}
                      >
                        <Row
                          textStyle={{
                            color: colors.primary[700],
                            fontSize: 14,
                            padding: 5,
                            fontFamily: 'Exo'
                          }}
                          key={index}
                          data={data}
                          style={{
                            backgroundColor:
                              rowData.declared_hours <= 12
                                ? 'white'
                                : colors.red[300]
                          }}
                          borderStyle={{
                            borderWidth: 0.5,
                            borderColor: colors.primary[500]
                          }}
                          widthArr={state.widthArr}
                        ></Row>
                      </Pressable>
                    )
                  })}
                </Table>
              </ScrollView>
            </Box>
          </ScrollView>
          <Box marginTop={'7'} padding={'2'}>
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              flexDirection={'row'}
              margin={2}
            >
              <Button onPress={pickImage}>
                <SimpleLineIcons name="camera" size={20} color="white" />
              </Button>
              <Button onPress={pickDocument}>
                <MaterialIcons name="attachment" size={20} color="white" />
              </Button>
              <Button onPress={() => submit()}>CONFIRMAR</Button>
            </Box>

            <Box backgroundColor={'white'} alignItems={'center'}>
              {images.map((e: any) => (
                <Box key={e.uri} marginY={2} borderColor={'blue.100'}>
                  <Button
                    onPress={() => deleteImage(e.uri)}
                    style={{ backgroundColor: colors.red[600] }}
                    textAlign={'center'}
                  >
                    Eliminar
                  </Button>
                  <Image
                    source={{ uri: e.uri }}
                    style={{ width: 200, height: 200 }}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </ScrollView>
  )
}

export default HoursReportReceiptNumberComponent
