import React, { createContext, ReactElement, useContext } from 'react'
import { useLogin } from './LoginContext'
import {
  getAccountAnalyticLineForAprobed,
  getAccountAnalyticLineByRecepitNumber
} from '../services/OdooService'

const GetDataContext = createContext({
  getAccountAnalyticLineForAprobed: async (): Promise<any> => {},
  getAccountAnalyticLineByRecepitNumber: async (): Promise<any> => {}
})

type GetDataContextProvider = {
  children: ReactElement;
};
export const GetDataProvider = ({
  children
}: GetDataContextProvider): ReactElement => {
  const { odooInstance } = useLogin()
  const getAccountAnalyticLineForAprobedContext = () => {
    return getAccountAnalyticLineForAprobed(odooInstance)
  }
  const getAccountAnalyticLineByRecepitNumberContext = (
    receiptNumber: string
  ) => {
    return getAccountAnalyticLineByRecepitNumber(odooInstance, receiptNumber)
  }

  return (
    <GetDataContext.Provider
      value={{
        getAccountAnalyticLineForAprobed:
          getAccountAnalyticLineForAprobedContext,
        getAccountAnalyticLineByRecepitNumber:
          getAccountAnalyticLineByRecepitNumberContext
      }}
    >
      {children}
    </GetDataContext.Provider>
  )
}

export function useGetData () {
  const context = useContext(GetDataContext)

  return context
}
