import { Box, useTheme } from 'native-base'
import React from 'react'
import ApproverHoursComponent from '../components/ApproverHoursComponent'
const ApproverHours = ({ navigation }) => {
  const { colors } = useTheme()

  return (
    <Box style={{ backgroundColor: colors.primary[50] }}>
      <ApproverHoursComponent navigation={navigation} />
    </Box>
  )
}

export default ApproverHours
