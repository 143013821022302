import { storeDataObject, getDataObject } from '../../utils/AsyncStorage'
import { employeType } from '../../types'
const groupArray = require('group-array')

const setEmployeesMemory = async (costCenters: Array<employeType>) => {
  await storeDataObject('employees', costCenters)
}
const getEmployeesMemory = async (): Promise<employeType[]> => {
  const data = await getDataObject('employees')
  return data
}
const getEmployeesMemoryById = async (
  employeeId: number
): Promise<employeType[]> => {
  const data = await getDataObject('employees')
  return data?.find((element: employeType) => element.id === employeeId)
}

const groupByEmploye = (employes) => {
  const dataFiltered = []
  if (employes && employes.length) {
    let data = employes.map((r) => {
      return {
        date: r.date,
        employe: r.employee_id[0],
        employee_id: r.employee_id,
        declared_hours: r.declared_hours
      }
    })
    data = groupArray(data, 'employe', 'date')
    for (const key in data) {
      for (const keyChild in data[key]) {
        let sum = 0
        const obj = {
          employee_id: data[key][keyChild][0].employee_id,
          date: data[key][keyChild][0].date
        }
        for (let i = 0; i < data[key][keyChild].length; i++) {
          sum += data[key][keyChild][i].declared_hours
        }
        obj.declared_hours = sum
        dataFiltered.push(obj)
      }
    }
  }
  return dataFiltered
}
export {
  setEmployeesMemory,
  getEmployeesMemory,
  getEmployeesMemoryById,
  groupByEmploye
}
