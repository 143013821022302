import {
  Button,
  CheckIcon,
  FormControl,
  Input,
  ScrollView,
  Select,
  Text,
  VStack,
  HStack,
  Alert,
  Box
} from 'native-base'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useSendData } from '../contexts/SendDataContext'
import { usePreload } from '../contexts/PreloadContext'
import { employeType, taskProjectType } from '../types'
import { useRoute } from '@react-navigation/native'
import { times } from '../contants/times'
import { dateDiffInHours, getDateTimeBeetwenTwoTimes } from '../utils/date'
import uuid from 'react-native-uuid'

const HoursReportComponent = ({ navigation }: any) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues
  } = useForm()
  const { getEmployes, getTasksByProject } = usePreload()
  const [employes, setEmployes] = useState<employeType[]>([])
  const [tasks, setTasks] = useState<taskProjectType[]>([])
  const { createDailyReport } = useSendData()
  const [approvingComment, setApprovingComment] = useState('')
  const [stateUntested, setStateUntested] = useState('pendiente')

  const [formState, setFormState] = useState({
    start_time: '06:30',
    end_time: '06:30'
  })
  const [declaredHours, setDeclaredHours] = useState('00:00')
  const calculateTimeMask = (datetime) => {
    if (datetime) {
      const time = datetime.split(' ')[1].substring(0, 5)
      return time
    }
    return ''
  }
  const route = useRoute()
  useEffect(() => {
    if (route.params) {
      if (route.params.id) {
        if (route.params.approving_comment) {
          setApprovingComment(route.params.approving_comment)
        }
        if (route.params.state_untested) {
          setStateUntested(route.params.state_untested)
        }
        getTasksByProject(route.params.project_id[0]).then(setTasks)
        setValue('name', route.params.name)
        setValue('receipt_number', route.params.receipt_number)
        setValue('task_id', `${route.params.task_id[0]}`)
        setValue('employee_id', `${route.params.employee_id[0]}`)
        setValue('partner_id', route.params.partner_id[0])
        setValue('project_id', route.params.project_id[0])
        setValue('date', route.params.date)
        setValue('start_time', calculateTimeMask(route.params.start_datetime))
        setValue('end_time', calculateTimeMask(route.params.end_datetime))
        const declaredHoursFloat = route.params.declared_hours
          ? parseFloat(route.params.declared_hours).toFixed(2)
          : '00:00'
        const declaredHoursParse = declaredHoursFloat.replace('.', ':')
        setDeclaredHours(`${declaredHoursParse}`)
      }
      if (!isNaN(route.params.project_id)) {
        getTasksByProject(route.params.project_id).then(setTasks)
      }
    } else {
      setDeclaredHours(
        dateDiffInHours(formState.start_time, formState.end_time)
      )
    }
    getEmployes().then(setEmployes)
  }, [])

  const onSubmit = async (): Promise<void> => {
    const values = getValues()

    const myAccountAnalityc = {
      ...formState,
      partner_id: values.partner_id ? values.partner_id : formState.partner_id,
      project_id: values.project_id ? values.project_id : formState.project_id,
      task_id: values.task_id ? parseInt(values.task_id) : formState.task_id,
      employee_id: values.employee_id
        ? parseInt(values.employee_id)
        : formState.employee_id,
      id: route.params && route.params.id ? route.params.id : null,
      state_untested: 'pendiente',
      status: 'draft',
      end_time: values.end_time,
      declared_hours: parseFloat(declaredHours.replace(':', '.')).toFixed(2),
      start_time: values.start_time,
      receipt_number: values.receipt_number
        ? values.receipt_number
        : formState.receipt_number,
      name: values.name ? values.name : formState.name,
      date: values.date ? values.date : formState.date
    }
    if (myAccountAnalityc.id) {
      const times = getDateTimeBeetwenTwoTimes(
        myAccountAnalityc.start_time,
        myAccountAnalityc.end_time,
        myAccountAnalityc.date
      )
      myAccountAnalityc.start_datetime = times.start_datetime
      myAccountAnalityc.end_datetime = times.end_datetime
      myAccountAnalityc.status = 'edit'
      delete myAccountAnalityc.end_time
      delete myAccountAnalityc.start_time

      await createDailyReport([myAccountAnalityc])
      navigation.navigate('Home')
    } else {
      myAccountAnalityc.id = uuid.v4()
      navigation.navigate('DailyReport', myAccountAnalityc)
    }
  }
  return (
    <ScrollView w={'100%'}>
      <Box
        borderRadius={'5'}
        shadow={'9'}
        backgroundColor={'white'}
        margin={2}
        padding={3}
      >
        {approvingComment && (
          <Alert
            w="100%"
            status={stateUntested === 'aprobado' ? 'success' : 'error'}
          >
            <VStack space={2} flexShrink={1} w="100%">
              <HStack flexShrink={1} space={2} justifyContent="space-between">
                <HStack space={2} flexShrink={1}>
                  <Alert.Icon mt="1" />
                  <Text fontSize="md" color="coolGray.800">
                    {approvingComment}
                  </Text>
                </HStack>
              </HStack>
            </VStack>
          </Alert>
        )}
        <VStack flex="1" w="95%" space={10}>
          <FormControl isRequired isInvalid={'employee_id' in errors}>
            <FormControl.Label>
              <Text>Operario</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  selectedValue={value}
                  accessibilityLabel="Seleccione Operario"
                  placeholder="Operario"
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size="5" />
                  }}
                  mt={1}
                  onValueChange={(val) => {
                    onChange(val)
                    setFormState({
                      ...formState,
                      employee_id: parseInt(val)
                    })
                  }}
                >
                  {employes?.map((el: employeType) => (
                    <Select.Item
                      key={el.id}
                      label={el.name}
                      value={String(el.id)}
                    />
                  ))}
                </Select>
              )}
              name="employee_id"
              rules={{ required: 'El operario es requerido.' }}
              defaultValue=""
            />
            <FormControl.ErrorMessage>
              {errors.operario?.message}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={'start_time' in errors}>
            <FormControl.Label>
              <Text>Hora de inicio</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Select
                    selectedValue={value}
                    accessibilityLabel="Seleccione hora inicio"
                    placeholder="Hora inicio"
                    _selectedItem={{
                      bg: 'teal.600',
                      endIcon: <CheckIcon size="5" />
                    }}
                    mt={1}
                    onValueChange={(val) => {
                      setFormState({
                        ...formState,
                        start_time: val
                      })
                      setDeclaredHours(
                        dateDiffInHours(val, formState.end_time)
                      )
                      onChange(val)
                    }}
                  >
                    {times?.map((el) => (
                      <Select.Item
                        key={el.value}
                        label={el.value}
                        value={el.value}
                      />
                    ))}
                  </Select>
                )
              }}
              name="start_time"
              defaultValue="06:30"
            />
            <FormControl.ErrorMessage>
              {errors.start_time?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl isInvalid={'end_time' in errors}>
            <FormControl.Label>
              <Text>Hora de finalización</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  selectedValue={value}
                  accessibilityLabel="Seleccione hora fin"
                  placeholder="Hora fin"
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size="5" />
                  }}
                  mt={1}
                  onValueChange={(val) => {
                    setFormState({
                      ...formState,
                      end_time: val
                    })
                    onChange(val)
                    setDeclaredHours(
                      dateDiffInHours(formState.start_time, val)
                    )
                  }}
                >
                  {times?.map((el) => (
                    <Select.Item
                      key={el.value}
                      label={el.value}
                      value={el.value}
                    />
                  ))}
                </Select>
              )}
              name="end_time"
              defaultValue="06:30"
            />
            <FormControl.ErrorMessage>
              {errors.end_time?.message}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl isInvalid={'declared_hours' in errors}>
            <FormControl.Label>
              <Text>Hora totales</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  isReadOnly
                  onBlur={onBlur}
                  placeholder={'Hora totales'}
                  onChangeText={onChange}
                  value={declaredHours}
                  type="text"
                />
              )}
              name="declared_hours"
              defaultValue=""
            />
            <FormControl.ErrorMessage>
              {errors.totalHours?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          <FormControl isRequired isInvalid={'task_id' in errors}>
            <FormControl.Label>
              <Text>Tarea</Text>
            </FormControl.Label>
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  selectedValue={value}
                  accessibilityLabel="Elija la tarea"
                  placeholder="Tarea"
                  _selectedItem={{
                    bg: 'teal.600',
                    endIcon: <CheckIcon size="5" />
                  }}
                  mt={1}
                  onValueChange={(val) => {
                    onChange(val)
                    setFormState({
                      ...formState,
                      task_id: parseInt(val)
                    })
                  }}
                >
                  {tasks?.map((el: taskProjectType) => (
                    <Select.Item
                      key={el.id}
                      label={el.name}
                      value={String(el.id)}
                    />
                  ))}
                </Select>
              )}
              name="task_id"
              rules={{ required: 'La tarea es requerida.' }}
              defaultValue=""
            />
            <FormControl.ErrorMessage>
              {errors.task?.message}
            </FormControl.ErrorMessage>
          </FormControl>

          <Button onPress={handleSubmit(onSubmit)}>GUARDAR</Button>
        </VStack>
      </Box>
    </ScrollView>
  )
}

export default HoursReportComponent

//
