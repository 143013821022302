import { storeDataObject, getDataObject } from '../../utils/AsyncStorage'

const setDailyReportImages = async (dailyReport: Array<any>) => {
  await storeDataObject('dailyReportImages', dailyReport)
}
const getDailyReportImages = async (): Promise<any> => {
  const data = await getDataObject('dailyReportImages')
  return data
}

const addImageDailyReport = async (accountAnalyticLine: any) => {
  let data = await getDailyReportImages()
  if (data && data.length) {
    data.push(accountAnalyticLine)
  } else {
    data = [accountAnalyticLine]
  }
  await setDailyReportImages(data)
}
const deleteImageDailyReportImagesById = async (accountAnalyticLineId: any) => {
  const data = await getDailyReportImages()
  if (data && data.length) {
    const index = data.findIndex((e) => e.id === accountAnalyticLineId)
    if (index > -1) {
      data.splice(index, 1)
      await setDailyReportImages(data)
    }
    return index
  }
}

export {
  setDailyReportImages,
  getDailyReportImages,
  addImageDailyReport,
  deleteImageDailyReportImagesById
}
