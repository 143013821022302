import { Box, useTheme } from 'native-base'
import React from 'react'
import LoadedReportsComponent from '../components/LoadedReportsComponent'

const LoadedReports = ({ navigation }: any) => {
  const { colors } = useTheme()

  return (
    <Box style={{ backgroundColor: colors.primary[50] }}>
      <LoadedReportsComponent navigation={navigation} />
    </Box>
  )
}

export default LoadedReports
