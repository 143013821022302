import { Box } from 'native-base'
import React from 'react'
import LoginComponent from '../components/LoginComponent'

const Login = () => {
  return (
    <Box flex={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <LoginComponent />
    </Box>
  )
}

export default Login
