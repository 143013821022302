import moment from 'moment'
const getDateTimeBeetwenTwoTimes = (
  startTime: string,
  endTime: string,
  date: string
): any => {
  const res = {
    start_datetime: moment(date.split(' ')[0] + ' ' + startTime).format(
      'YYYY-MM-DD HH:mm'
    ),
    end_datetime: moment(date.split(' ')[0] + ' ' + endTime).format(
      'YYYY-MM-DD HH:mm'
    )
  }
  if (startTime > endTime) {
    res.end_datetime = moment(date.split(' ')[0] + ' ' + endTime)
      .add(1, 'd')
      .format('YYYY-MM-DD HH:mm')
  }
  return res
}
const dateDiffInHours = (startTime, endTime) => {
  const today = moment().format('YYYY-MM-DD')
  const startAux = moment(today + ' ' + startTime).format('YYYY-MM-DD HH:mm')
  let endAux = moment(today + ' ' + endTime).format('YYYY-MM-DD HH:mm')
  if (startTime > endTime) {
    endAux = moment(today + ' ' + endTime)
      .add(1, 'd')
      .format('YYYY-MM-DD HH:mm')
  }
  const duration = moment.duration(moment(endAux).diff(startAux))
  let hours = parseInt(duration.asHours()) + ''
  let minutes = (parseInt(duration.asMinutes()) % 60) + ''
  if (hours.length === 1) {
    hours = '0' + hours
  }
  if (minutes.length === 1) {
    minutes = '0' + minutes
  }
  return hours + ':' + minutes
}

export { getDateTimeBeetwenTwoTimes, dateDiffInHours }
