import * as SecureStore from 'expo-secure-store'

const save = async (key: string, value: string) => {
  await SecureStore.setItemAsync(key, value)
}
const getValueFor = async (key: string) => {
  const result = await SecureStore.getItemAsync(key)
  return result
}
export { save, getValueFor }
