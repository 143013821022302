import React, { useState, useEffect } from 'react'
import {
  ScrollView,
  HStack,
  Pressable,
  useTheme,
  Modal,
  Text,
  VStack,
  Button,
  Select,
  CheckIcon,
  Box
} from 'native-base'
import { usePreload } from '../contexts/PreloadContext'
import LoadingComponent from './LoadingComponent'
import { Table, Row } from 'react-native-table-component'
import { useSendData } from '../contexts/SendDataContext'
import { StyleSheet, View } from 'react-native'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { employeType } from '../types'
import { groupByEmploye } from '../services/OdooServiceMemory'
import DatePickerComponent from './DatePickerComponent'
const ResumeComponent = ({ navigation }: any) => {
  const [accountAnalitycLine, setAccountAnalitycLine] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [element, setElement] = useState({})
  const [showModalFilter, setShowModalFilter] = useState(false)
  const { colors } = useTheme()
  const { createDailyReport } = useSendData()
  const {
    getEmployes,
    getMyAccountAnalyticLine,
    getMyAccountAnalyticLineByFilters
  } = usePreload()
  const [employes, setEmployes] = useState<employeType[]>([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [employe, setEmploye] = useState(0)
  const {
    control,
    formState: { errors },
    setValue
  } = useForm()
  const getDataFilter = () => {
    getMyAccountAnalyticLineByFilters(employe, startDate, endDate).then(
      (res) => {
        const data = groupByEmploye(res)
        setAccountAnalitycLine(data)
      }
    )
    setShowModalFilter(false)
  }
  useEffect(() => {
    getData()
    getEmployes().then(setEmployes)
  }, [])
  const getData = () => {
    setLoading(true)
    getMyAccountAnalyticLine().then((res) => {
      const data = groupByEmploye(res)
      setAccountAnalitycLine(data)
      setLoading(false)
    })
  }
  const state = {
    tableHead: ['Fecha', 'Persona', 'Total Horas'],
    widthArr: [90, 180, 100]
  }

  return (
    <ScrollView>
      {loading && <LoadingComponent />}
      {!loading && (
        <Box
          borderRadius={'3'}
          shadow={'1'}
          backgroundColor={'white'}
          margin={2}
          padding={3}
        >
          <VStack marginBottom={2}>
            <Button onPress={() => setShowModalFilter(true)}>
              Seleccionar Filtro
            </Button>
          </VStack>

          <ScrollView horizontal={true}>
            <Modal
              isOpen={showModalFilter}
              onClose={() => setShowModalFilter(false)}
            >
              <Modal.Content>
                <Modal.Body backgroundColor={'white'}>
                  <Text>Ingrese filtros</Text>
                  <VStack>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          selectedValue={value}
                          accessibilityLabel="Seleccione Operario"
                          placeholder="Operario"
                          _selectedItem={{
                            bg: 'teal.600',
                            endIcon: <CheckIcon size="5" />
                          }}
                          mt={1}
                          onValueChange={(val) => {
                            onChange(val)
                            setEmploye(parseInt(val))
                          }}
                        >
                          {employes?.map((el: employeType) => (
                            <Select.Item
                              key={el.id}
                              label={el.name}
                              value={String(el.id)}
                            />
                          ))}
                        </Select>
                      )}
                      name="employee_id"
                      rules={{ required: 'El operario es requerido.' }}
                      defaultValue=""
                    />
                  </VStack>
                  <VStack>
                    <Text marginTop={3}>Fecha Inicio:</Text>
                    <DatePickerComponent sendDate={setStartDate} size={14} />
                  </VStack>
                  <VStack>
                    <Text marginTop={3}>Fecha Fin:</Text>
                    <DatePickerComponent sendDate={setEndDate} size={14} />
                  </VStack>
                  <VStack marginTop={2}>
                    <HStack space={5}>
                      <Button
                        onPress={() => {
                          setValue('employee_id', null)
                          setEndDate('')
                          setStartDate('')
                          getData()
                          setShowModalFilter(false)
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button onPress={() => getDataFilter()}>Aceptar</Button>
                    </HStack>
                  </VStack>
                </Modal.Body>
              </Modal.Content>
            </Modal>
            <View>
              <Table>
                <Row
                  textStyle={{
                    color: 'white',
                    fontSize: 13,
                    padding: 5,
                    fontFamily: 'Exo'
                  }}
                  data={state.tableHead}
                  style={{
                    backgroundColor: colors.primary[700]
                  }}
                  widthArr={state.widthArr}
                />
              </Table>
              <ScrollView>
                <Table>
                  {accountAnalitycLine.map((rowData, index) => {
                    const data = [
                      rowData.date
                        ? moment(rowData.date).format('DD/MM/YY')
                        : '',
                      rowData.employee_id ? rowData.employee_id[1] : '',
                      rowData.declared_hours
                        ? parseFloat(rowData.declared_hours).toFixed(2)
                        : '00.00'
                    ]
                    return (
                      <Row
                        textStyle={{
                          color: colors.primary[700],
                          fontSize: 13,
                          padding: 5,
                          paddingTop: 10,
                          paddingBottom: 10,
                          fontFamily: 'Exo'
                        }}
                        key={index}
                        data={data}
                        style={{
                          backgroundColor:
                            rowData.declared_hours <= 12
                              ? 'white'
                              : colors.red[300]
                        }}
                        borderStyle={{
                          borderWidth: 0.5,
                          borderColor: colors.primary[500]
                        }}
                        widthArr={state.widthArr}
                      ></Row>
                    )
                  })}
                </Table>
              </ScrollView>
            </View>
          </ScrollView>
        </Box>
      )}
    </ScrollView>
  )
}

export default ResumeComponent
