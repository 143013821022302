import * as React from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import Login from '../screens/Login'
import StatusComponent from '../components/StatusComponent'
import Home from '../screens/Home'
import DailyReport from '../screens/DailyReport'
import LoadedReports from '../screens/LoadedReports'
import HoursReport from '../screens/HoursReport'
import HoursReportReceiptNumber from '../screens/HoursReportReceiptNumber'
import Config from '../screens/Config'
import ApproverHours from '../screens/ApproverHours'
import Resume from '../screens/Resume'
import ApproverHoursDetail from '../screens/ApproverHoursDetail'
import { useLogin } from '../contexts/LoginContext'

const Stack = createNativeStackNavigator()

const MainStack = () => {
  const { isLogin } = useLogin()

  return (
    <NavigationContainer>
      <Stack.Navigator>
        {!isLogin && (
          <Stack.Screen
            name="Login"
            component={Login}
            options={{ title: 'Login' }}
          />
        )}
        {isLogin && (
          <>
            <Stack.Screen
              name="Home"
              component={Home}
              options={{
                title: 'Bienvenido',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="DailyReport"
              component={DailyReport}
              options={{
                title: 'Parte diario',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="LoadedReports"
              component={LoadedReports}
              options={{
                title: 'Mis parte de horas',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="HoursReport"
              component={HoursReport}
              options={{
                title: 'Parte de horas',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="HoursReportByReceiptNumber"
              component={HoursReportReceiptNumber}
              options={{
                title: 'Parte de horas',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="Config"
              component={Config}
              options={{
                title: 'Configuración',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="ApproverHours"
              component={ApproverHours}
              options={{
                title: 'Listado de aprobación',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="ApproverHoursDetail"
              component={ApproverHoursDetail}
              options={{
                title: 'Detalle de aprobación',
                headerRight: () => <StatusComponent />
              }}
            />
            <Stack.Screen
              name="Resume"
              component={Resume}
              options={{
                title: 'Resumen',
                headerRight: () => <StatusComponent />
              }}
            />
          </>
        )}
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default MainStack
