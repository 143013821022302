import {
  Button,
  Box,
  Text,
  VStack,
  Alert,
  HStack,
  CloseIcon,
  IconButton
} from 'native-base'
import React, { useState } from 'react'
import {
  setDailyReport,
  setDailyReportImages
} from '../services/OdooServiceMemory'
import { useLogin } from '../contexts/LoginContext'
import { usePreload } from '../contexts/PreloadContext'
const ConfigComponent = ({ navigation }) => {
  const { logout } = useLogin()
  const { preloadData } = usePreload()
  const [messageAlert, setMessageAlert] = useState('')
  const [showAlert, setShowAlert] = useState(false)

  const callPreload = async () => {
    await preloadData()
    setMessageAlert('Éxito al reacargar los datos')
    setShowAlert(true)
  }
  const resetDailyReport = async () => {
    await setDailyReportImages([])
    await setDailyReport([])
    setMessageAlert('Éxito al restablecer la operacion')
    setShowAlert(true)
  }
  return (
    <Box
      borderRadius={'3'}
      shadow={'9'}
      backgroundColor={'white'}
      margin={2}
      padding={3}
    >
      {showAlert && (
        <Alert w="100%" status={'success'}>
          <VStack space={2} flexShrink={1} w="100%">
            <HStack flexShrink={1} space={2} justifyContent="space-between">
              <HStack space={2} flexShrink={1}>
                <Alert.Icon mt="1" />
                <Text fontSize="md" color="coolGray.800">
                  {messageAlert}
                </Text>
              </HStack>
              <IconButton
                variant="unstyled"
                onPress={() => setShowAlert(false)}
                _focus={{
                  borderWidth: 0
                }}
                icon={<CloseIcon size="3" color="coolGray.600" />}
              />
            </HStack>
          </VStack>{' '}
        </Alert>
      )}
      <VStack margin={2}>
        <Button onPress={resetDailyReport}>
          <Text color="white">Restabler aplicación</Text>
        </Button>
      </VStack>
      <VStack margin={2}>
        <Button onPress={callPreload}>
          <Text color="white">Recargar Datos</Text>
        </Button>
      </VStack>
      <VStack margin={2}>
        <Button onPress={logout}>
          <Text color="white">Cerrar Sesión</Text>
        </Button>
      </VStack>
    </Box>
  )
}

export default ConfigComponent
