import { extendTheme } from 'native-base'

export const theme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        rounded: 'md',
        shadow: '9',
        style: {
          backgroundColor: '#2d4764'
        }
      }
    },
    Text: {
      baseStyle: {
        fontFamily: 'Exo'
      }
    }
  },
  colors: {
    // Primary color (Blue) from logo and website
    primary: {
      50: '#adbfde',
      100: '#849ecd',
      300: '#6b88b0',
      500: '#EDEDF0',
      700: '#2d4764',
      900: '#0b1531'
    },
    // Secondary color (gray)
    secondary: {
      200: '#dbd1c4',
      300: '#9a989a'
    },
    yellow: {
      100: '#d47c04'
    }
  },
  typography: {
    letterSpacings: {
      xs: '-0.05em',
      sm: '-0.025em',
      md: 0,
      lg: '0.025em',
      xl: '0.05em',
      '2xl': '0.1em'
    },
    lineHeights: {
      '2xs': '1em',
      xs: '1.125em',
      sm: '1.25em',
      md: '1.375em',
      lg: '1.5em',
      xl: '1.75em',
      '2xl': '2em',
      '3xl': '2.5em',
      '4xl': '3em',
      '5xl': '4em'
    },
    fontWeights: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
      extrablack: 950
    },
    // TODO: Select fonts
    fonts: {
      heading: 'Montserrat',
      body: 'Montserrat',
      mono: 'Montserrat'
    },
    fontSizes: {
      '2xs': 10,
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
      '2xl': 24,
      '3xl': 30,
      '4xl': 36,
      '5xl': 48,
      '6xl': 60,
      '7xl': 72,
      '8xl': 96,
      '9xl': 128
    },
    config: {
      initialColorMode: 'light'
    }
  }
})
