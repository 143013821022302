import React, {
  createContext,
  ReactElement,
  useContext,
  useState,
  useEffect
} from 'react'
import { useLogin } from './LoginContext'
import {
  getCostCenter,
  getEmployes,
  getTasks,
  getMyAccountAnalyticLine,
  getTasksByProject
} from '../services/OdooService'
import {
  setCostCenterMemory,
  setEmployeesMemory,
  setTaskMemory,
  getCostCenterMemory,
  getEmployeesMemory,
  getTaskMemory,
  getTaskByProjectMemory,
  getMyAccountAnalyticLineMemory,
  getDailyReportByReceiptNumberMemory,
  setMyAccountAnalyticLineMemory,
  getMyAccountAnalyticLineByFiltersMemory
} from '../services/OdooServiceMemory'
import { status } from '../contants/StatusApp'
import NetInfo from '@react-native-community/netinfo'

const { refresh, noData, okData, oldData } = status
const PreloadContext = createContext({
  getCostCenter: async (): Promise<any> => {},
  getEmployes: async (): Promise<any> => {},
  getTasks: async (): Promise<any> => {},
  getTasksByProject: async (projectId: number): Promise<any> => {},
  getMyAccountAnalyticLine: getMyAccountAnalyticLineMemory,
  getDailyReportByReceiptNumber: getDailyReportByReceiptNumberMemory,
  getMyAccountAnalyticLineByFilters: getMyAccountAnalyticLineByFiltersMemory,
  status: noData,
  preloadData: (): void => {}
})

type PreloadContextProvider = {
  children: ReactElement;
};
export const PreloadProvider = ({
  children
}: PreloadContextProvider): ReactElement => {
  const [statusApp, setStatusApp] = useState(noData)
  const { odooInstance, isLogin } = useLogin()
  const getMyAccountAnalyticLineOnline = async () => {
    const { isConnected } = await NetInfo.fetch()
    if (isConnected) {
      const response = await getMyAccountAnalyticLine(odooInstance)
      await setMyAccountAnalyticLineMemory(response)
    }
    return getMyAccountAnalyticLineMemory()
  }
  const preloadData = () => {
    setStatusApp(refresh)
    Promise.all([
      getCostCenter(odooInstance),
      getEmployes(odooInstance),
      getTasks(odooInstance),
      getMyAccountAnalyticLine(odooInstance)
    ]).then((resOdooService) => {
      Promise.all([
        setCostCenterMemory(resOdooService[0]),
        setEmployeesMemory(resOdooService[1]),
        setTaskMemory(resOdooService[2]),
        setMyAccountAnalyticLineMemory(resOdooService[3])
      ]).then(() => {
        setStatusApp(okData)
      })
    })
  }
  useEffect(() => {
    Promise.all([
      getCostCenterMemory(),
      getEmployeesMemory(),
      getTaskMemory()
    ]).then((resolve) => {
      if (resolve[0] || resolve[1] || resolve[2]) {
        setStatusApp(oldData)
      }
      if (isLogin) {
        NetInfo.addEventListener((state) => {
          if (state.isInternetReachable) {
            preloadData(odooInstance)
          } else {
            setStatusApp(oldData)
          }
        })
      }
    })
  }, [isLogin])

  const getCostCenterContext = async () => {
    const { isConnected } = await NetInfo.fetch()
    if (isConnected) {
      return getCostCenter(odooInstance)
    } else {
      return getCostCenterMemory()
    }
  }
  const getEmployesContext = async () => {
    const { isConnected } = await NetInfo.fetch()
    if (isConnected) {
      return getEmployes(odooInstance)
    } else {
      return getEmployeesMemory()
    }
  }
  const getTasksContext = async () => {
    const { isConnected } = await NetInfo.fetch()
    if (isConnected) {
      return getTasks(odooInstance)
    } else {
      return getTaskMemory()
    }
  }
  const getTasksByProjectContext = async (projectId) => {
    const { isConnected } = await NetInfo.fetch()
    if (isConnected) {
      return getTasksByProject(odooInstance, projectId)
    } else {
      return getTaskByProjectMemory(projectId)
    }
  }

  return (
    <PreloadContext.Provider
      value={{
        getCostCenter: getCostCenterContext,
        getEmployes: getEmployesContext,
        getTasks: getTasksContext,
        getTasksByProject: getTasksByProjectContext,
        getMyAccountAnalyticLine: getMyAccountAnalyticLineOnline,
        getDailyReportByReceiptNumber: getDailyReportByReceiptNumberMemory,
        getMyAccountAnalyticLineByFilters:
          getMyAccountAnalyticLineByFiltersMemory,
        status: statusApp,
        preloadData
      }}
    >
      {children}
    </PreloadContext.Provider>
  )
}

export function usePreload () {
  const context = useContext(PreloadContext)

  return context
}
