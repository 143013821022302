export const responses = {
  successResponse: {
    error: false,
    code: 10,
    message: 'Éxito al realzar la operacion'
  },
  errorResponse: {
    error: true,
    code: 9,
    message: 'Error al realizra la operacion'
  }
}
