import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect
} from 'react'
import { useLogin } from './LoginContext'
import {
  createAccountAnalyticLine,
  updateAccountAnalyticLine,
  deleteAccountAnalyticLine,
  createVoucherApprover
} from '../services/OdooService'
import {
  getDailyReport,
  changeStatusAnalyticLineDailyReport,
  deleteAnalyticLineDailyReport,
  addMyAccountAnalyticLineMemory,
  changeMyAccountAnalyticLineMemory,
  getCostCenterMemoryById,
  getEmployeesMemoryById,
  getTaskMemoryById,
  addAnalyticLineDailyReport,
  getMyAccountAnalyticLineMemoryById,
  deleteAnalyticLineDailyReportById,
  deleteMyAnalyticLineMemoryById,
  addImageDailyReport,
  deleteImageDailyReportImagesById,
  getDailyReportImages
} from '../services/OdooServiceMemory'
import NetInfo from '@react-native-community/netinfo'
import { accountAnalyticLineType } from '../types/index'
import { responses } from '../contants/ResponseSendData'

const { successResponse, errorResponse } = responses
const SendDataContext = createContext({
  createDailyReport: (dailyReport: accountAnalyticLineType[]) => {},
  updateStatusApproved: async (
    idsAnalytic,
    status,
    comment
  ): Promise<any> => {},
  createDailyReportImages: () => {}
})

type SendDataContextProvider = {
  children: ReactElement;
};
export const SendDataProvider = ({
  children
}: SendDataContextProvider): ReactElement => {
  let isFetched = true
  const { odooInstance, isLogin } = useLogin()
  const createDailyReportInMemory = async () => {
    if (isFetched) {
      isFetched = false
      await executeCreateDailyReport()
      await executeCreateDailyReportImages()
      isFetched = true
      return successResponse
    }
  }
  const executeCreateDailyReportImages = async () => {
    const { isConnected } = await NetInfo.fetch()

    if (isConnected) {
      const dailyReportInMemory = await getDailyReportImages()
      try {
        for (const report of dailyReportInMemory) {
          const res = await createVoucherApprover(odooInstance, report)
          if (res) {
            await deleteImageDailyReportImagesById(report.id)
          } else {
            return errorResponse
          }
        }
      } catch (error) {}
    }
  }
  const executeCreateDailyReport = async () => {
    const { isConnected } = await NetInfo.fetch()
    //  if (isFetched) {
    //    isFetched = false
    const dailyReportInMemory = await getDailyReport()
    if (dailyReportInMemory) {
      for (let i = 0; i < dailyReportInMemory.length; i++) {
        if (isConnected) {
          try {
            const accountAnalityc = {
              ...dailyReportInMemory[i]
            }
            if (accountAnalityc.project_id) {
              accountAnalityc.project_id = accountAnalityc.project_id.id
            }
            const status = accountAnalityc.status
            delete accountAnalityc.status
            let res = null
            if (status === 'publish') {
              res = await createAccountAnalyticLine(
                odooInstance,
                accountAnalityc
              )
            } else if (status === 'edit') {
              res = await updateAccountAnalyticLine(
                odooInstance,
                accountAnalityc
              )
            } else if (status === 'delete') {
              res = await deleteAccountAnalyticLine(
                odooInstance,
                accountAnalityc.id
              )
            }
            if (res) {
              await deleteAnalyticLineDailyReport(dailyReportInMemory[i])
            } else {
              return errorResponse
            }
          } catch (e) {
            return errorResponse
          }
        } else {
          if (dailyReportInMemory[i].project_id) {
            const constCenter = await getCostCenterMemoryById(
              dailyReportInMemory[i].project_id
            )
            dailyReportInMemory[i].project_id = [
              constCenter.id,
              constCenter.name
            ]
            if (dailyReportInMemory[i].partner_id) {
              dailyReportInMemory[i].partner_id = constCenter.partner_id
              const employee = await getEmployeesMemoryById(
                dailyReportInMemory[i].employee_id
              )
              dailyReportInMemory[i].employee_id = [employee.id, employee.name]
            }
            if (dailyReportInMemory[i].task_id) {
              const task = await getTaskMemoryById(
                dailyReportInMemory[i].task_id
              )
              dailyReportInMemory[i].task_id = [task.id, task.name]
              //
            }
          } else {
            // Es una linea analitica ya borrada
            await deleteMyAnalyticLineMemoryById(dailyReportInMemory[i].id)
            await deleteAnalyticLineDailyReportById(dailyReportInMemory[i].id)
          }
          if (dailyReportInMemory[i].status === 'publish') {
            const elem = await getMyAccountAnalyticLineMemoryById(
              dailyReportInMemory[i].id
            )
            /* Si esta la linea analitica no la vuelvo a pushear */
            if (!elem) {
              await addMyAccountAnalyticLineMemory(dailyReportInMemory[i])
            }
          } else if (dailyReportInMemory[i].status === 'edit') {
            await changeMyAccountAnalyticLineMemory(dailyReportInMemory[i])
          } else if (dailyReportInMemory[i].status === 'delete') {
            await deleteMyAnalyticLineMemoryById(dailyReportInMemory[i].id)
          }
        }
      }
      /*   }

      isFetched = true */
    }
  }
  const updateStatusApproved = async (idsAnalytic, status, comment) => {
    for (let i = 0; i < idsAnalytic.length; i++) {
      const analyticLine = {
        id: idsAnalytic[i],
        approving_comment: comment,
        state_untested: status
      }
      await updateAccountAnalyticLine(odooInstance, analyticLine)
    }
  }
  const createDailyReportImages = async (dailyReportImages) => {
    for (const image of dailyReportImages) {
      await addImageDailyReport(image)
    }
    await executeCreateDailyReportImages()
    return successResponse
  }
  const createDailyReport = async (dailyReport: accountAnalyticLineType[]) => {
    let dailyReportInMemory = await getDailyReport()
    for (let i = 0; i < dailyReport.length; i++) {
      const index =
        dailyReportInMemory && dailyReportInMemory.length
          ? dailyReportInMemory &&
            dailyReportInMemory.findIndex((e) => e.id === dailyReport[i].id)
          : -1
      if (index > -1) {
        if (dailyReportInMemory[index].status === 'publish') {
          // elimino y cambio estado de la nueva a publish
          await deleteAnalyticLineDailyReportById(dailyReport[i].id)
          dailyReport[i].status = 'publish'
          await addAnalyticLineDailyReport(dailyReport[i])
        } else if (dailyReportInMemory[index].status === 'edit') {
          // elimino
          await deleteAnalyticLineDailyReportById(dailyReport[i].id)
          await addAnalyticLineDailyReport(dailyReport[i])
        } else if (dailyReportInMemory[index].status === 'delete') {
          await deleteAnalyticLineDailyReportById(dailyReport[i].id)
          if (Number.isInteger(dailyReport[i].id)) {
            await addAnalyticLineDailyReport(dailyReport[i])
          }
        }
      } else {
        await addAnalyticLineDailyReport(dailyReport[i])
      }
    }
    dailyReportInMemory = await getDailyReport()

    for (const accountAnalyticLine of dailyReportInMemory) {
      if (accountAnalyticLine.status === 'draft') {
        await changeStatusAnalyticLineDailyReport(
          accountAnalyticLine,
          'publish'
        )
      }
    }
    await executeCreateDailyReport()
    return successResponse
  }
  useEffect(() => {
    if (isLogin) {
      NetInfo.addEventListener((state) => {
        if (state.isInternetReachable) {
          createDailyReportInMemory()
        }
      })
    }
  }, [isLogin])

  return (
    <SendDataContext.Provider
      value={{
        createDailyReport,
        updateStatusApproved,
        createDailyReportImages
      }}
    >
      {children}
    </SendDataContext.Provider>
  )
}

export function useSendData () {
  const context = useContext(SendDataContext)

  return context
}
