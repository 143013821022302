import AsyncStorage from '@react-native-async-storage/async-storage'
const storeData = async (key: string, value: string) => {
  try {
    await AsyncStorage.setItem(key, value)
  } catch (e) {
    // saving error
  }
}
const storeDataObject = async (key: string, value: object) => {
  try {
    const jsonValue = JSON.stringify(value)
    await AsyncStorage.setItem(key, jsonValue)
  } catch (e) {
    // saving error
  }
}

const getData = async (key: string): Promise<any> => {
  try {
    const value = await AsyncStorage.getItem(key)
    return value
  } catch (e) {
    // error reading value
  }
}
const getDataObject = async (key: string): Promise<any> => {
  try {
    const jsonValue = await AsyncStorage.getItem(key)
    return jsonValue != null ? JSON.parse(jsonValue) : null
  } catch (e) {
    // error reading value
  }
}

export { storeData, storeDataObject, getData, getDataObject }
