import React, { useEffect, useState } from 'react'
import { useRoute } from '@react-navigation/native'
import {
  VStack,
  HStack,
  Text,
  Box,
  ScrollView,
  useTheme,
  Button,
  Modal,
  TextArea
} from 'native-base'
import { useGetData } from '../contexts/GetDataContext'
import { useSendData } from '../contexts/SendDataContext'
import { Table, Row } from 'react-native-table-component'
import { Image } from 'react-native'
import moment from 'moment'
import LoadingComponent from './LoadingComponent'
const ApproverHoursDetailComponent = ({ navigation }) => {
  const { getAccountAnalyticLineByRecepitNumber } = useGetData()
  const [accountAnalitycLine, setAccountAnalitycLine] = useState([])
  const [receiptNumber, setReceiptNumber] = useState('')
  const [partner, setPartner] = useState('')
  const [costCenter, setCostCenter] = useState('')
  const [totalHours, setTotalHours] = useState('')
  const { colors } = useTheme()
  const [showModal, setShowModal] = useState(false)
  const [stateUntested, setStateUntested] = useState('pendiente')
  const [approvingComment, setApprovingComment] = useState('')
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState('')
  const { updateStatusApproved } = useSendData()
  const state = {
    tableHead: ['Inicio', 'Fin', 'Total', 'Empleado', 'Tarea'],
    widthArr: [120, 120, 80, 100, 200]
  }
  const route = useRoute()
  const submit = async () => {
    const idsaccountAnalitycLine = accountAnalitycLine.map((e) => e.id)
    await updateStatusApproved(
      idsaccountAnalitycLine,
      stateUntested,
      approvingComment
    )
    navigation.navigate('ApproverHours', {})
  }
  useEffect(() => {
    if (route.params.receipt_number) {
      setLoading(true)
      getAccountAnalyticLineByRecepitNumber(route.params.receipt_number).then(
        (res) => {
          setAccountAnalitycLine(res)
          setReceiptNumber(res[0].receipt_number)
          setPartner(res[0].partner_id[1])
          setCostCenter(res[0].project_id[1])
          setDate(res[0].date)
          setTotalHours((): any => {
            let sum = 0
            res.forEach((element) => {
              sum += element.declared_hours
            })
            return sum
          })
          setImages(res.receipt_image)
          setLoading(false)
        }
      )
    }
  }, [])
  return (
    <ScrollView>
      {loading && <LoadingComponent />}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <Modal.Content>
          <Modal.Body backgroundColor={'white'}>
            <Text fontSize={'sm'}>
              Esta a punto de{' '}
              {stateUntested === 'aprobado'
                ? (
                  <Text fontWeight={'black'} color={colors.green[500]}>
                  APROBAR
                  </Text>
                )
                : (
                  <Text fontWeight={'black'} color={colors.red[500]}>
                  RECHAZAR
                  </Text>
                )}{' '}
              el parte diario
            </Text>

            <TextArea
              marginTop={2}
              autoCompleteType={false}
              placeholder="Deje su comentario"
              onChangeText={(val) => {
                setApprovingComment(val)
              }}
            ></TextArea>
            <HStack space={4} paddingTop={3}>
              <Button onPress={submit}>Aceptar</Button>
              <Button
                onPress={() => {
                  setShowModal(false)
                }}
              >
                Cancelar
              </Button>
            </HStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>

      {!loading && (
        <Box
          borderRadius={'5'}
          shadow={'9'}
          backgroundColor={'white'}
          margin={2}
          padding={3}
        >
          <HStack>
            <Text fontWeight={'black'}>N° Comprobante: </Text>
            <Text>{receiptNumber}</Text>
          </HStack>
          <HStack>
            <Text fontWeight={'black'}>Cliente: </Text>
            <Text>{partner}</Text>
          </HStack>
          <HStack>
            <Text fontWeight={'black'}>Centro de costo: </Text>
            <Text>{costCenter}</Text>
          </HStack>
          <HStack>
            <Text fontWeight={'black'}>Fecha: </Text>
            <Text>{date}</Text>
          </HStack>
          <HStack>
            <Text fontWeight={'black'}>Horas totales: </Text>
            <Text>{totalHours}</Text>
          </HStack>
          <HStack space={2} marginX={1} padding={1}>
            <Button
              onPress={() => {
                setStateUntested('rechazado')
                setShowModal(true)
              }}
              backgroundColor={colors.red[600]}
            >
              <Text fontWeight={'black'} color={'white'}>
                Rechazar
              </Text>
            </Button>
            <Button
              onPress={() => {
                setStateUntested('aprobado')
                setShowModal(true)
              }}
              backgroundColor={colors.green[600]}
            >
              <Text fontWeight={'black'} color={'white'}>
                Autorizar
              </Text>
            </Button>
          </HStack>
        </Box>
      )}
      {!loading && (
        <ScrollView horizontal={true}>
          <Box
            borderRadius={'3'}
            shadow={'1'}
            backgroundColor={'white'}
            margin={2}
            padding={3}
          >
            <Table>
              <Row
                textStyle={{
                  color: 'white',
                  fontSize: 16,
                  padding: 5,
                  fontFamily: 'Exo'
                }}
                data={state.tableHead}
                style={{
                  backgroundColor: colors.primary[700]
                }}
                widthArr={state.widthArr}
              />
            </Table>

            <Table>
              {accountAnalitycLine.map((rowData, index) => {
                const data = [
                  rowData.start_datetime
                    ? moment(rowData.start_datetime).format('DD/MM/YY HH:mm')
                    : '',
                  rowData.end_datetime
                    ? moment(rowData.start_datetime).format('DD/MM/YY HH:mm')
                    : '',
                  rowData.declared_hours
                    ? parseFloat(rowData.declared_hours).toFixed(2)
                    : '00.00',
                  rowData.task_id ? rowData.task_id[1] : '',
                  rowData.employee_id ? rowData.employee_id[1] : ''
                ]
                return (
                  <Row
                    textStyle={{
                      color: colors.primary[700],
                      fontSize: 14,
                      padding: 5,
                      paddingTop: 10,
                      paddingBottom: 10,
                      fontFamily: 'Exo'
                    }}
                    key={index}
                    data={data}
                    style={{
                      backgroundColor:
                        rowData.declared_hours <= 12
                          ? 'white'
                          : colors.red[300]
                    }}
                    borderStyle={{
                      borderWidth: 0.5,
                      borderColor: colors.primary[500]
                    }}
                    widthArr={state.widthArr}
                  ></Row>
                )
              })}
            </Table>
          </Box>
        </ScrollView>
      )}
      {!loading && (
        <Box
          margin={2}
          padding={3}
          alignItems={'center'}
          borderRadius={'3'}
          shadow={'9'}
          backgroundColor={'white'}
        >
          {images.map((e: any) => (
            <Box key={e.receipt_image} marginY={2} borderColor={'blue.100'}>
              <Image
                source={{ uri: 'data:image/png;base64,' + e.receipt_image }}
                style={{ width: 200, height: 200 }}
              />
            </Box>
          ))}
        </Box>
      )}
    </ScrollView>
  )
}

export default ApproverHoursDetailComponent
