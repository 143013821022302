import { Box, useTheme } from 'native-base'
import React from 'react'
import HoursReportReceiptNumberComponent from '../components/HoursReportReceiptNumberComponent'
const HoursReportReceiptNumber = ({ navigation }: any) => {
  const { colors } = useTheme()

  return (
    <Box style={{ backgroundColor: colors.primary[50] }}>
      <HoursReportReceiptNumberComponent navigation={navigation} />
    </Box>
  )
}

export default HoursReportReceiptNumber
