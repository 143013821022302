const localStorageSpace = () => {
  let allStrings = ''
  for (const key in window.localStorage) {
    allStrings += key
    if (window.localStorage.hasOwnProperty(key)) {
      allStrings += window.localStorage[key]
    }
  }
  return allStrings
    ? 3 + (allStrings.length * 16) / (8 * 1024) + ' KB'
    : 'Empty (0 KB)'
}

const shallowEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1)
  for (const key of keys1) {
    if (typeof object1[key] !== 'object') {
      if (object1[key] !== object2[key]) {
        return false
      }
    }
  }
  return true
}

const formatDate = (date) => {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0')
}
const formatDateTime = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  )
}

export { shallowEqual, formatDate, formatDateTime, localStorageSpace }
