import { storeDataObject, getDataObject } from '../../utils/AsyncStorage'
import { accountAnalyticLineType } from '../../types'
import { shallowEqual } from '../../utils'

const setMyAccountAnalyticLineMemory = async (costCenters: any) => {
  await storeDataObject('myAccountAnalytic', costCenters)
}
const getMyAccountAnalyticLineMemory = async (): Promise<any> => {
  const data = await getDataObject('myAccountAnalytic')
  return data
}
const setMyAnalyticLine = async (
  accountAnalyticLine: accountAnalyticLineType
) => {
  let data = await getMyAccountAnalyticLineMemory()
  if (data && data.length) {
    data.push(accountAnalyticLine)
  } else {
    data = [accountAnalyticLine]
  }
  await setMyAccountAnalyticLineMemory(data)
}

const setMyAccountAnalyticLineMemoryWithPublish = async (
  accountAnalyticLines: any
) => {
  let data = await getMyAccountAnalyticLineMemory()
  if (data && data.length) {
    data = data.filter((element) => element.status === 'publish')
  } else {
    data = []
  }
  await storeDataObject('myAccountAnalytic', [
    ...accountAnalyticLines,
    ...data
  ])
}
const getMyAccountAnalyticLineByFiltersMemory = async (
  employeId?: number,
  startDate?: string,
  endDate?: string
) => {
  const data = await getMyAccountAnalyticLineMemory()
  if (data && data.length) {
    return data.filter(
      (e: any) =>
        (employeId ? employeId === e.employee_id[0] : true) &&
        (startDate ? startDate <= e.date : true) &&
        (endDate ? endDate >= e.date : true)
    )
  }
  return data
}

const addMyAccountAnalyticLineMemory = async (accountAnalyticLine: any) => {
  const data = await getMyAccountAnalyticLineMemory()
  let dataAux = null
  if (data) {
    data.push(accountAnalyticLine)
    dataAux = data
  } else {
    dataAux = [accountAnalyticLine]
  }
  await storeDataObject('myAccountAnalytic', dataAux)
}

const deleteMyAnalyticLine = async (
  accountAnalyticLine: accountAnalyticLineType
) => {
  const data = await getMyAccountAnalyticLineMemory()
  if (data && data.length) {
    const index = data.findIndex((e) => shallowEqual(e, accountAnalyticLine))
    if (index > -1) {
      data.splice(index, 1)
      await setMyAccountAnalyticLineMemory(data)
    }
    return index
  }
}

const deleteMyAnalyticLineMemoryById = async (accountAnalyticLineId: any) => {
  const data = await getMyAccountAnalyticLineMemory()
  if (data && data.length) {
    await setMyAccountAnalyticLineMemory(
      data.filter((e) => e.id !== accountAnalyticLineId)
    )
    // TODO: Ver que no me elimina las lineas craedas y eliminadas
    return data
  }
}
const changeMyAccountAnalyticLineMemory = async (
  accountAnalyticLine: accountAnalyticLineType
) => {
  const data = await getMyAccountAnalyticLineMemory()
  const index = data.findIndex((e) => e.id === accountAnalyticLine.id)

  if (index > -1) {
    data[index] = accountAnalyticLine
    await setMyAccountAnalyticLineMemory(data)
  }
}
const getMyAccountAnalyticLineMemoryById = async (
  analyticId: number
): Promise<accountAnalyticLineType[]> => {
  const data = await getDataObject('myAccountAnalytic')
  return data?.find(
    (element: accountAnalyticLineType) => element.id === analyticId
  )
}

export {
  deleteMyAnalyticLine,
  setMyAnalyticLine,
  setMyAccountAnalyticLineMemory,
  getMyAccountAnalyticLineMemory,
  setMyAccountAnalyticLineMemoryWithPublish,
  addMyAccountAnalyticLineMemory,
  changeMyAccountAnalyticLineMemory,
  getMyAccountAnalyticLineMemoryById,
  deleteMyAnalyticLineMemoryById,
  getMyAccountAnalyticLineByFiltersMemory
}
