import React from 'react'
import { Box, useTheme } from 'native-base'
import DailyReportComponent from '../components/DailyReportComponent'
import type { RootStackProps } from '../screens/Home'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

type Props = NativeStackScreenProps<RootStackProps, 'HoursReport'>;

const DailyReport = ({ navigation }: Props) => {
  const { colors } = useTheme()

  return (
    <Box style={{ backgroundColor: colors.primary[50] }}>
      <DailyReportComponent navigation={navigation} />
    </Box>
  )
}

export default DailyReport
