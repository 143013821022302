import React, {
  createContext,
  useState,
  ReactElement,
  useContext
} from 'react'
import { database, host } from '@env'
import Odoo from 'odoo-rcp-async'
import { getValueFor, save } from '../utils/SecureStorage'
import NetInfo from '@react-native-community/netinfo'
import { codeResponse } from '../contants/ResponseLogin'
const LoginContext = createContext({
  isLogin: false,
  login: async (username: string, password: string): Promise<any> => {},
  logout: async (): Promise<void> => {},
  getData: async (): Promise<any> => {},
  odooInstance: {}
})

type LoginContextProvider = {
  children: ReactElement;
};
export const LoginProvider = ({
  children
}: LoginContextProvider): ReactElement => {
  const [logged, isLogged] = useState(false)
  const [odooInstance, setOdooInstance] = useState({})
  const login = async (username: string, password: string): Promise<any> => {
    const odoo = new Odoo({
      host,
      database,
      username,
      password
    })
    const {
      loginSuccessOnline,
      loginSuccessOffline,
      loginNotMatchOnline,
      loginNotMatchOffline,
      loginErrServer
    } = codeResponse
    const { isConnected } = await NetInfo.fetch()
    if (isConnected) {
      try {
        const connect = await odoo.connect()
        if (connect.result) {
          const partnerId = `${connect.result.partner_id}`
          const uid = `${connect.result.uid}`
          await save('username', username)
          await save('password', password)
          await save('uid', uid)
          await save('partner_id', partnerId)
          setOdooInstance(odoo)
          isLogged(true)
          return loginSuccessOnline
        } else {
          return loginNotMatchOnline
        }
      } catch (error) {
        console.log(error)
        return loginErrServer
      }
    } else {
      const credentials = await getData()
      if (
        credentials.username &&
        credentials.password &&
        credentials.username === username &&
        credentials.password === password
      ) {
        setOdooInstance(odoo)
        isLogged(true)
        return loginSuccessOffline
      } else {
        return loginNotMatchOffline
      }
    }
  }

  const logout = async (): Promise<void> => {
    // await save('username', '')
    // await save('password', '')
    isLogged(false)
  }

  const getData = async (): Promise<any> => {
    const username = await getValueFor('username')
    const password = await getValueFor('password')
    return { username, password }
  }

  return (
    <LoginContext.Provider
      value={{
        isLogin: logged,
        login,
        logout,
        getData,
        odooInstance
      }}
    >
      {children}
    </LoginContext.Provider>
  )
}

export function useLogin () {
  const context = useContext(LoginContext)

  return context
}
