import { getDataObject, storeDataObject } from '../../utils/AsyncStorage'
import { costCenterType, taskProjectType } from '../../types'
const setCostCenterMemory = async (costCenters: Array<costCenterType>) => {
  await storeDataObject('costCenters', costCenters)
}
const getCostCenterMemory = async (): Promise<costCenterType[]> => {
  const data = await getDataObject('costCenters')
  return data
}

const getCostCenterMemoryById = async (
  projectId: number
): Promise<taskProjectType[]> => {
  const data = await getDataObject('costCenters')
  return data?.find((element: taskProjectType) => element.id === projectId)
}

export { setCostCenterMemory, getCostCenterMemory, getCostCenterMemoryById }
