export const codeResponse = {
  loginSuccessOnline: {
    error: false,
    code: 200,
    message: 'Usuario logueado con éxito'
  },
  loginSuccessOffline: {
    error: false,
    code: 201,
    message: 'Usuario logueado con éxito modo offline'
  },
  loginNotMatchOnline: {
    error: true,
    code: 500,
    message: 'Usuario y contraseña no corresponden'
  },
  loginNotMatchOffline: {
    error: true,
    code: 501,
    message: 'Usuario y contraseña no corresponden modo offline'
  },
  loginErrServer: {
    error: true,
    code: 502,
    message: 'Error al conectarse al servidor'
  }
}
