import { storeDataObject, getDataObject } from '../../utils/AsyncStorage'
import { accountAnalyticLineType } from '../../types'
import { shallowEqual } from '../../utils'

const setDailyReport = async (dailyReport: Array<accountAnalyticLineType>) => {
  await storeDataObject('dailyReport', dailyReport)
}
const getDailyReport = async (): Promise<accountAnalyticLineType[]> => {
  const data = await getDataObject('dailyReport')
  return data
}
const addAnalyticLineDailyReport = async (
  accountAnalyticLine: accountAnalyticLineType
) => {
  let data = await getDailyReport()
  if (data && data.length) {
    data.push(accountAnalyticLine)
  } else {
    data = [accountAnalyticLine]
  }
  await setDailyReport(data)
}
const getDailyReportByReceiptNumberMemory = async (receiptNumber: string) => {
  const data = await getDailyReport()
  return data?.filter(
    (element: accountAnalyticLineType) =>
      element.receipt_number === receiptNumber
  )
}
const getDailyReportByReceiptNumberMemoryByState = async (
  receiptNumber: string,
  status: string
) => {
  const data = await getDailyReport()
  return data?.filter(
    (element: accountAnalyticLineType) =>
      element.receipt_number === receiptNumber && element.status === status
  )
}

const changeStatusAnalyticLineDailyReport = async (
  accountAnalyticLine: accountAnalyticLineType,
  status: string
) => {
  const data = await getDailyReport()
  const index = data.findIndex((e) => shallowEqual(e, accountAnalyticLine))

  if (index > -1) {
    data[index].status = status
    await setDailyReport(data)
  }
  await setDailyReport(data)
}
const deleteAnalyticLineDailyReport = async (
  accountAnalyticLine: accountAnalyticLineType
) => {
  const data = await getDailyReport()
  if (data && data.length) {
    const index = data.findIndex((e) => shallowEqual(e, accountAnalyticLine))
    if (index > -1) {
      data.splice(index, 1)
      await setDailyReport(data)
    }
    return index
  }
}
const deleteAnalyticLineDailyReportById = async (
  accountAnalyticLineId: any
) => {
  const data = await getDailyReport()
  if (data && data.length) {
    const index = data.findIndex((e) => e.id === accountAnalyticLineId)
    if (index > -1) {
      data.splice(index, 1)
      await setDailyReport(data)
    }
    return index
  }
}

export {
  setDailyReport,
  getDailyReport,
  addAnalyticLineDailyReport,
  deleteAnalyticLineDailyReport,
  changeStatusAnalyticLineDailyReport,
  getDailyReportByReceiptNumberMemory,
  getDailyReportByReceiptNumberMemoryByState,
  deleteAnalyticLineDailyReportById
}
