import { Box, Text, Spinner, HStack } from 'native-base'
import React from 'react'

const LoadingComponent = () => {
  return (
    <Box
      borderRadius={'3'}
      shadow={'1'}
      backgroundColor={'white'}
      margin={2}
      padding={3}
    >
      <HStack>
        <Text color="primary.700" fontSize={'lg'}>
          Por favor aguarde un momento
        </Text>
      </HStack>
      <HStack>
        <Spinner accessibilityLabel="Cargando" size="lg" />
      </HStack>
    </Box>
  )
}

export default LoadingComponent
