import { View } from 'react-native'
import React, { useEffect, useState } from 'react'
import { useRoute, useNavigation } from '@react-navigation/native'
import LoadingComponent from './LoadingComponent'
import { useGetData } from '../contexts/GetDataContext'
import { ScrollView, Pressable, useTheme, Box } from 'native-base'
import { Table, Row } from 'react-native-table-component'
import moment from 'moment'

const ApproverHoursComponent = ({ navigation }) => {
  const { colors } = useTheme()
  const state = {
    tableHead: ['Fecha', 'Comprobante', 'Total Horas', 'Cliente', 'Proyecto'],
    widthArr: [80, 160, 100, 140, 140]
  }
  const [accountAnalitycLine, setAccountAnalitycLine] = useState([])
  const [loading, setLoading] = useState(false)
  const route = useRoute()
  const { getAccountAnalyticLineForAprobed } = useGetData()
  useEffect(() => {
    setLoading(true)
    getAccountAnalyticLineForAprobed().then((res) => {
      setAccountAnalitycLine(res)
      setLoading(false)
    })
  }, [route.params])
  return (
    <ScrollView horizontal={true}>
      {loading && <LoadingComponent />}
      {!loading && (
        <Box
          borderRadius={'3'}
          shadow={'1'}
          backgroundColor={'white'}
          margin={2}
          padding={3}
        >
          <Table>
            <Row
              textStyle={{
                color: 'white',
                fontSize: 16,
                padding: 5,
                fontFamily: 'Exo'
              }}
              data={state.tableHead}
              style={{
                backgroundColor: colors.primary[700]
              }}
              widthArr={state.widthArr}
            />
          </Table>
          <ScrollView>
            <Table>
              {accountAnalitycLine.map((rowData, index) => {
                const data = [
                  rowData['date:day']
                    ? rowData['date:day'] /* moment(rowData['date:day'], 'll', 'es')
                      .locale('en')
                      .format('DD/MM/YY') */
                    : '',
                  rowData.receipt_number ? rowData.receipt_number : '',
                  rowData.declared_hours
                    ? parseFloat(rowData.declared_hours).toFixed(2)
                    : '00.00',
                  rowData.partner_id ? rowData.partner_id[1] : '',
                  rowData.project_id ? rowData.project_id[1] : ''
                ]
                return (
                  <Pressable
                    onPress={() => {
                      navigation.navigate('ApproverHoursDetail', {
                        receipt_number: rowData.receipt_number
                      })
                    }}
                    key={index}
                  >
                    <Row
                      textStyle={{
                        color: colors.primary[700],
                        fontSize: 16,
                        padding: 5,
                        fontFamily: 'Exo'
                      }}
                      key={index}
                      data={data}
                      style={{ backgroundColor: 'white' }}
                      borderStyle={{
                        borderWidth: 0.5,
                        borderColor: colors.primary[500]
                      }}
                      widthArr={state.widthArr}
                    ></Row>
                  </Pressable>
                )
              })}
            </Table>
          </ScrollView>
        </Box>
      )}
    </ScrollView>
  )
}

export default ApproverHoursComponent
