export const status = {
  refresh: {
    message: 'Sincronizando',
    code: 100,
    status: 'REFRESHING'
  },
  noData: {
    message: 'Por favor aguarde a que sincronicen los datos',
    code: 101,
    status: 'NO_DATA'
  },
  okData: {
    message: 'Conectado',
    code: 102,
    status: 'ONLINE'
  },
  oldData: {
    message: 'No Conectado',
    code: 103,
    status: 'OFFLINE'
  }
}
