import { Box, useTheme } from 'native-base'
import React from 'react'
import ApproverHoursDetailComponent from '../components/ApproverHoursDetailComponent'
const ApproverHoursDetail = ({ navigation }) => {
  const { colors } = useTheme()
  return (
    <Box style={{ backgroundColor: colors.primary[50] }}>
      <ApproverHoursDetailComponent navigation={navigation} />
    </Box>
  )
}

export default ApproverHoursDetail
