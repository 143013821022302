import { accountAnalyticLineType } from '../types'
import { getValueFor } from '../utils/SecureStorage'
const getCostCenter = (odooInstance: object) =>
  new Promise((resolve, reject) => {
    try {
      odooInstance
        .search_read('project.project', {
          fields: ['name', 'partner_id'],
          domain: [['partner_id', '!=', false]]
        })
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })

const getEmployes = (odooInstance: object) =>
  new Promise((resolve, reject) => {
    try {
      odooInstance
        .search_read('hr.employee', {
          fields: ['name']
        })
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })

const getTasks = (odooInstance: object) =>
  new Promise((resolve, reject) => {
    try {
      odooInstance
        .search_read('project.task', {
          fields: ['name', 'project_id'],
          domain: [['is_closed', '=', false]]
        })
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })
const getTasksByProject = (odooInstance: object, projectId) =>
  new Promise((resolve, reject) => {
    try {
      odooInstance
        .search_read('project.task', {
          fields: ['name', 'project_id'],
          domain: [
            ['is_closed', '=', false],
            ['project_id', '=', projectId]
          ]
        })
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })

const createVoucherApprover = async (
  odooInstance: object,
  voucherLine: any
) => {
  const uid = await getValueFor('uid')
  // const uid = 2
  voucherLine.create_uid = parseInt(uid)
  return new Promise((resolve, reject) => {
    try {
      odooInstance
        .create('project.voucher.approver', voucherLine)
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })
}

const createAccountAnalyticLine = async (
  odooInstance: object,
  accountAnalyticLine: accountAnalyticLineType
) => {
  const uid = await getValueFor('uid')
  // const uid = 2
  accountAnalyticLine.create_uid = parseInt(uid)
  return new Promise((resolve, reject) => {
    try {
      odooInstance
        .create('account.analytic.line', accountAnalyticLine)
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })
}
const updateAccountAnalyticLine = async (
  odooInstance: object,
  accountAnalyticLine: accountAnalyticLineType
) => {
  // TODO: Creo que no es necesario const uuid = /* await getValueFor('uuid') */ 2
  // TODO: Creo que no es necesario accountAnalyticLine.create_uid = parseInt(uuid)
  return new Promise((resolve, reject) => {
    try {
      odooInstance
        .update(
          'account.analytic.line',
          accountAnalyticLine.id,
          accountAnalyticLine
        )
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })
}

const deleteAccountAnalyticLine = async (
  odooInstance: object,
  accountAnalyticLineId: number
) => {
  return new Promise((resolve, reject) => {
    try {
      odooInstance
        .delete('account.analytic.line', accountAnalyticLineId)
        .then((response) => resolve(response))
    } catch (error) {
      reject(error)
    }
  })
}
const getAccountAnalyticLineByRecepitNumber = async (
  odooInstance: object,
  receiptNumber: string
) => {
  return new Promise((resolve, reject) => {
    try {
      odooInstance
        .search_read('account.analytic.line', {
          domain: [['receipt_number', '=', receiptNumber]],
          fields: [
            'receipt_number',
            'approving_comment',
            'state_untested',
            'date',
            'start_datetime',
            'end_datetime',
            'declared_hours',
            'partner_id',
            'project_id',
            'task_id',
            'name',
            'create_uid',
            'employee_id'
          ]
        })
        .then((response) => {
          odooInstance
            .search_read('project.voucher.approver', {
              domain: [['receipt_number', '=', receiptNumber]],
              fields: ['receipt_image']
            })
            .then((responseImage) => {
              response.receipt_image = responseImage
              resolve(response)
            })
        })
    } catch (error) {
      reject(error)
    }
  })
}

const getMyAccountAnalyticLine = async (odooInstance: object) => {
  const uid = await getValueFor('uid')
  // const uid = 2
  return new Promise((resolve, reject) => {
    try {
      odooInstance
        .search_read('account.analytic.line', {
          domain: [['create_uid', '=', parseInt(uid)]],
          fields: [
            'date',
            'task_id',
            'project_id',
            'partner_id',
            'employee_id',
            'name',
            'receipt_number',
            'start_datetime',
            'end_datetime',
            'create_uid',
            'approving_user',
            'declared_hours',
            'state_untested',
            'approving_comment'
          ]
        })
        .then((response) => {
          resolve(response)
        })
    } catch (error) {
      reject(error)
    }
  })
}
const getAccountAnalyticLineForAprobed = async (odooInstance: object) => {
  const partner_id = await getValueFor('partner_id')
  // const partner_id = 3
  return new Promise((resolve, reject) => {
    try {
      odooInstance
        .read_group('account.analytic.line', {
          domain: [
            ['hour_approver_ids.name.partner_id', '=', parseInt(partner_id)],
            ['receipt_number', '!=', false],
            ['state_untested', 'in', ['pendiente', 'rechazado']]
          ],
          groupby: ['receipt_number', 'date:day', 'project_id', 'partner_id'],
          fields: ['declared_hours'], // TODO: Aca va a mostrar las horas que falta aprobar
          lazy: false
        })
        .then(async (response) => {
          resolve(response)
        })
    } catch (error) {
      reject(error)
    }
  })
}

export {
  getCostCenter,
  getEmployes,
  getTasks,
  createAccountAnalyticLine,
  getMyAccountAnalyticLine,
  updateAccountAnalyticLine,
  deleteAccountAnalyticLine,
  getAccountAnalyticLineForAprobed,
  getAccountAnalyticLineByRecepitNumber,
  createVoucherApprover,
  getTasksByProject
}
