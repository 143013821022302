/* eslint-disable react/jsx-key */
import {
  Box,
  Button,
  FormControl,
  Input,
  VStack,
  Text,
  useTheme,
  Spinner,
  Modal,
  Image,
  Card
} from 'native-base'
import React, { useState } from 'react'
import { useLogin } from '../contexts/LoginContext'
import { useForm, Controller } from 'react-hook-form'
const logo = require('../assets/logo.png')

interface DataProps {
  field: string;
  label: string;
  placeholder: string;
  requiredMsg: string;
  type: 'text' | 'password';
}

const LoginComponent = () => {
  const { login } = useLogin()
  const { colors } = useTheme()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<boolean>(false)
  const [messageLogin, setMessageLogin] = useState<string>('')

  const onSubmit = async (data: any): Promise<any> => {
    setLoading(true)
    const { username, password } = data
    const res = await login(username, password)

    if (res.error) {
      setMessageLogin(res.message)
      setLoading(false)
      setLoginError(true)
    }
  }

  const dataArr: DataProps[] = [
    {
      field: 'username',
      label: 'Usuario',
      placeholder: 'Ingrese su usuario',
      requiredMsg: 'El nombre de usuario es requerido.',
      type: 'text'
    },
    {
      field: 'password',
      label: 'Contraseña',
      placeholder: 'Ingrese su contraseña',
      requiredMsg: 'La contraseña es requerida.',
      type: 'password'
    }
  ]

  return (
    <Box
      width="100%"
      backgroundColor={colors.primary[50]}
      style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}
    >
      <Modal isOpen={loginError} onClose={() => setLoginError(false)}>
        <Modal.Content
          /* maxW={'70%'} h={'20%'} */ maxWidth="90%"
          padding={'20px'}
          backgroundColor="red.100"
        >
          <Modal.CloseButton />
          <Modal.Body
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Text fontSize={'md'}>{messageLogin}</Text>
          </Modal.Body>
        </Modal.Content>
      </Modal>

      <VStack
        space={12}
        w={'100%'}
        h={'60%'}
        borderRadius={5}
        backgroundColor={'white'}
        maxWidth={300}
        shadow={'9'}
        style={{
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Card borderRadius={100} backgroundColor={colors.primary[700]}>
          <Image
            resizeMode={'contain'}
            paddingBottom={10}
            borderRadius={100}
            size={100}
            source={logo}
            alt="Alternate Text"
          />
        </Card>

        <VStack space={5}>
          {dataArr.map((el: DataProps) => (
            <FormControl
              isRequired
              isInvalid={el.field in errors}
              key={el.field}
            >
              <FormControl.Label>
                <Text fontSize={'md'}>{el.label}</Text>
              </FormControl.Label>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    onBlur={onBlur}
                    w={'4/5'}
                    placeholder={el.placeholder}
                    onChangeText={onChange}
                    value={value}
                    type={el.type}
                  />
                )}
                name={el.field}
                rules={{ required: el.requiredMsg, minLength: 3 }}
                defaultValue=""
              />
              <FormControl.ErrorMessage>
                {errors[el.field]?.type === 'required'
                  ? errors[el.field]?.message
                  : errors[el.field]?.type === 'minLength' &&
                    'Debe constar con al menos 3 caracteres.'}
              </FormControl.ErrorMessage>
            </FormControl>
          ))}
        </VStack>
        <Button
          w={'3/5'}
          onPress={handleSubmit(onSubmit)}
          style={{ backgroundColor: colors.primary[700] }}
          colorScheme="pink"
          shadow={'9'}
        >
          {!isLoading
            ? (
              <Text fontSize={'2xl'} fontWeight={'600'} color="white">
              ACEPTAR
              </Text>
            )
            : (
              <>
                <Spinner accessibilityLabel="validando datos" />
                <Text color={'white'}>Validando datos...</Text>
              </>
            )}
        </Button>
      </VStack>
    </Box>
  )
}

export default LoginComponent
