import { HStack } from 'native-base'
import React from 'react'
import { SimpleLineIcons, Foundation, AntDesign } from '@expo/vector-icons'
import { usePreload } from '../contexts/PreloadContext'

const StatusComponent = () => {
  const { status } = usePreload()

  return (
    <HStack
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
      }}
      w="80%"
      paddingRight={5}
      space={3}
    >
      {status.status === 'NO_DATA' && (
        <>
          <AntDesign name="exclamationcircleo" size={28} color="red" />
        </>
      )}
      {status.status === 'ONLINE' && (
        <>
          <Foundation name="web" size={36} color="green" />
        </>
      )}
      {status.status === 'REFRESHING' && (
        <>
          <SimpleLineIcons name="refresh" size={28} color="gold" />
        </>
      )}
      {status.status === 'OFFLINE' && (
        <>
          <Foundation name="web" size={36} color="red" />
        </>
      )}
    </HStack>
  )
}

export default StatusComponent
