import MainStack from './stack/MainStack'
import { LoginProvider } from './contexts/LoginContext'
import { PreloadProvider } from './contexts/PreloadContext'
import { SendDataProvider } from './contexts/SendDataContext'
import { GetDataProvider } from './contexts/GetDataContext'
import { NativeBaseProvider } from 'native-base'
import React from 'react'
import { theme } from './contants/theme'
export default function App () {
  return (
    <NativeBaseProvider theme={theme}>
      <LoginProvider>
        <PreloadProvider>
          <SendDataProvider>
            <GetDataProvider>
              <MainStack />
            </GetDataProvider>
          </SendDataProvider>
        </PreloadProvider>
      </LoginProvider>
    </NativeBaseProvider>
  )
}
