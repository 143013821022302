import DatePicker, { getFormatedDate } from 'react-native-modern-datepicker'
import React, { useState } from 'react'
import { Text, HStack, useTheme, Button } from 'native-base'
import { Entypo } from '@expo/vector-icons'

const DatePickerComponent = ({ sendDate, size }) => {
  const [date, setDate] = useState('')
  const { colors } = useTheme()
  const [open, setOpen] = useState(false)
  return (
    <>
      {!open && (
        <HStack
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
          }}
        >
          <Button
            onPress={() => setOpen(true)}
            shadow={'9'}
            color={colors.primary[700]}
            style={{ borderRadius: 50 }}
          >
            <Entypo color={'white'} name="calendar" size={size || 36} />
          </Button>
          <Text paddingTop={2} paddingLeft={2} fontSize={'lg'}>
            {date}
          </Text>
        </HStack>
      )}
      {open && (
        <DatePicker
          mode="calendar"
          onSelectedChange={(date) => {
            setDate(getFormatedDate(date, 'YYYY-MM-DD'))
            setOpen(false)
            sendDate(getFormatedDate(date, 'YYYY-MM-DD'))
          }}
        />
      )}
    </>
  )
}

export default DatePickerComponent
